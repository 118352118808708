body,
html {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	height: 100vh;
}

*::-webkit-scrollbar {
	width: 7px;
}

*::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb {
	box-sizing: unset;
	background-color: #00bcd4;
	border-radius: 3px;
	border: 3px solid rgba(0, 0, 0, 0);
}

main {
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.navbar {
	height: 60px;
}

.main-section {
	margin-top: 60px;
	height: 100vh;
}
