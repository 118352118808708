/* IOS SPECIFIC CSS */
@supports (-webkit-overflow-scrolling: touch) {
	.root {
		background-image: url("https://aoc.zerotomastery.io/images/advent.png");
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
		/* height: 100%; */
	}

	@media only screen and (max-width: 600px) {
		.root {
			background-image: url("https://aoc.zerotomastery.io/images/advent-mobile.png");
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			background-attachment: fixed;
			/* height: 100%; */
		}
	}
}

/* OTHER CSS */
.root {
	background-image: url("https://aoc.zerotomastery.io/images/advent.png");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	/* height: 100%; */
}

/* .content {
	display: flex;
	justify-content: center;
	align-items: center;
	 height: 100%; 
	 position: relative; 
	flex-direction: column;
} */

@media only screen and (max-width: 600px) {
	.root {
		background-image: url("https://aoc.zerotomastery.io/images/advent-mobile.png");
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
		/* height: 100%; */
	}
}
